import React from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { startOfWeek, endOfWeek, shiftMonth, startOfYear, endOfYear, startOfFinancialYear, endOfFinancialYear, shiftWeek, shiftHours } from "../../utils/dateFunctions";
import ReportDateSelector from "../../shared/ReportDateSelector";
import WarehouseSelector from "../../components/WarehouseSelector";
import SalesOrdersCreate from './FunctionalComp/SalesOrdersCreate';
import FormModal from '../../components/FormModal';
import ProjectSales from './Forms/ProjectSales';
import SalesOrdersEditable from './Tables/SalesOrdersEditable';
import { checkAccess } from '../../utils/roleFunctions';
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import { Table, TableContainer, TableRow, TableCell, TableBody, TableHead } from '@mui/material';
import Paper from '@mui/material/Paper';
const SalesOrdersPage = (props) => {
    const [startDate, setStartDate] = React.useState(startOfFinancialYear(new Date()));
    const [endDate, setEndDate] = React.useState(endOfFinancialYear(new Date()));
    const [type, setType] = React.useState(0);
    const [data, setData] = React.useState(null);
    const [completed, setCompleted] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [series, setSeries] = React.useState(false);
    const [location, setLocation] = React.useState(0);
    const [reload, setReload] = React.useState(true);
    const requiredEditAccess = { key: ACCESS_SECTIONS.SALES, value: ACCESS.EDIT };

    const reloadFunc = () => {
        setReload(!reload);
    }

    const columns = React.useMemo(
        () => {
            var movingDate = new Date(startDate);
            var cols = [];
            while (movingDate < endDate) {
                if (type <= 1) {
                    var cpyDate = shiftMonth(new Date(movingDate), 1);

                    cols.push({ title: movingDate.toLocaleString('default', { month: 'short' }) + ' ' + movingDate.getFullYear(), start: movingDate, end: cpyDate });
                    movingDate = shiftMonth(movingDate, 1);
                } else if (type === 2) {
                    var cpyDate = new Date(movingDate);
                    cpyDate = endOfWeek(cpyDate);
                    if (cpyDate > endDate) {
                        cpyDate = new Date(endDate);
                    }
                    cols.push({ title: ((movingDate.getDate() + '/' + (movingDate.getMonth() + 1)) + ' - ' + (cpyDate.getDate() + '/' + (cpyDate.getMonth() + 1))), start: movingDate, end: cpyDate });
                    movingDate = shiftWeek(movingDate, 1).start;

                } else if (type === 3) {
                    var cpyDate = shiftHours(new Date(movingDate), 24);
                    cols.push({ title: movingDate.toLocaleString('default', { weekday: 'long' }), start: movingDate, end: cpyDate });
                    movingDate = shiftHours(movingDate, 24);
                }
            }
            return cols;
        },
        [startDate, endDate, type])
    return (
        <React.Fragment>

            <Grid container spacing={2} mb={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <ReportDateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} type={type} setType={setType} />
                </Grid>
                {/*<Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ textAlign: "right" }}>*/}
                {/*    {checkAccess(props.access, requiredEditAccess) ?*/}
                {/*        <RollOutButton {...props} />:*/}
                {/*        null}*/}
                {/*</Grid>*/}
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    {/*<WarehouseSelector location={location} setLocation={setLocation} />*/}
                </Grid>
            </Grid>

            <Grid container spacing={3.5}>

                <Grid item xs={12}>
                    <Paper >
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((col) => {
                                            return <TableCell>{col.title}</TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Row 1</TableCell>
                                        <TableCell>Row 2</TableCell>
                                        <TableCell>Row 3</TableCell>
                                        <TableCell>Row 4</TableCell>
                                        <TableCell>Row 5</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment >
    );
};

//const RollOutButton = (props) => {
//    const [open, setOpen] = React.useState(false);
//    const [completed, setCompleted] = React.useState(false);

//    const openForm = () => {
//        setOpen(true);

//    }
//    const handleClose = () => {
//        setCompleted(false);
//        setOpen(false);
//    }


//    return <React.Fragment>
//        <FormModal open={open} handleClose={handleClose} title={"Project Sales"} >
//            <ProjectSales access={props.access} reload={props.reloadFunc} handleClose={handleClose} completed={completed} />
//        </FormModal>
//        <Button onClick={openForm} variant="contained" style={{ marginBottom: '5px' }}>{"Project Sales forward"}</Button>

//    </React.Fragment>
//}

export default SalesOrdersPage;
