import React from 'react';
import { Select, InputLabel, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { shiftHours,startOfFinancialYear,endOfFinancialYear, startOfMonth, endOfMonth, startOfYear, endOfYear, endOfWeek, startOfWeek } from "../utils/dateFunctions";
import TextField from '@mui/material/TextField';
import { DateTime } from 'luxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import DatePickerOutline from './DatePicker';
import FormControl from "@mui/material/FormControl";
import Stack from '@mui/material/Stack';
import MenuItem from "@mui/material/MenuItem";
const DateSelector = (props) => {
    const [type, setType] = React.useState(props.type);
    const [endDate, setEndDate] = React.useState(props.endDate);
    const [startDate, setStartDate] = React.useState(props.startDate);

    const endDateSetter = (date) => {
        console.log(date);
        setEndDate(date);
        props.setEndDate(date);
    }
    const startDateSetter = (date) => {
        setStartDate(date);
        props.setStartDate(date);
    }
    return (
        <React.Fragment>
            <ToggleButtonGroup color='primary' value={type} exclusive onChange={(event, value) => {
                setType(value);
                props.setType(value);
            }}>
                <ToggleButton onClick={() => {
                    endDateSetter(endOfFinancialYear(DateTime.now().toJSDate()));
                    startDateSetter(startOfFinancialYear(DateTime.now().toJSDate()));
                }} value={0}>Financial Year</ToggleButton>
                <ToggleButton onClick={() => {
                    endDateSetter(endOfYear(DateTime.now().toJSDate()));
                    startDateSetter(startOfYear(DateTime.now().toJSDate()));
                }} value={1}>Year</ToggleButton>
                <ToggleButton onClick={() => {
                    endDateSetter(endOfMonth(DateTime.now().toJSDate()));
                    startDateSetter(startOfMonth(DateTime.now().toJSDate()));
                }}
                    value={2}>Month</ToggleButton>
                <ToggleButton
                    onClick={() => {
                        endDateSetter(endOfWeek(DateTime.now().toJSDate()));
                        startDateSetter(startOfWeek(DateTime.now().toJSDate()));
                    }} value={3}>Week</ToggleButton>
                {/* <ToggleButton value={3}>Day</ToggleButton> */}
            </ToggleButtonGroup>
            <span style={{ paddingLeft: '5px' }}>
                {type === 0 ? <FinYearSelector value={DateTime.fromJSDate(endOfFinancialYear(endDate))} setDate={endDateSetter} setStartDate={startDateSetter} /> : null}
                {type === 1 ? <YearSelector value={DateTime.fromJSDate(endDate)} setDate={endDateSetter} setStartDate={startDateSetter} /> : null}
                {type === 2 ? <MonthSelector value={DateTime.fromJSDate(endDate)} setDate={endDateSetter} setStartDate={startDateSetter} /> : null}
                {type === 3 ? <WeekSelector value={DateTime.fromJSDate(endDate)} setDate={endDateSetter} setStartDate={startDateSetter} /> : null}
            </span>
            <DatePickerOutline date={startDate} setDate={startDateSetter} label="Start Date" />
            <DatePickerOutline date={endDate} setDate={endDateSetter} label="End Date" />
        </React.Fragment>
    )
}

export default DateSelector;
const FinYearSelector = (props) => {
    const [value, setValue] = React.useState((props.value));
    const [open, setOpen] = React.useState(false);
    return (<LocalizationProvider dateAdapter={AdapterLuxon} >
        <DatePicker
            views={['year']}
            label={props.title}
            value={value}
            openTo="year"
            open={open}
            onChange={(newValue) => {
                setValue(newValue);
                props.setDate(endOfFinancialYear(newValue.toJSDate()));
                props.setStartDate(startOfFinancialYear(newValue.toJSDate()));
            }}
            onMonthChange={() => { setOpen(false) }}
            onOpen={() => { setOpen(true) }}
            onClose={() => { setOpen(false) }}
            inputFormat="yyyy"
            renderInput={(params) => <TextField {...params} helperText={null} />}
        />
    </LocalizationProvider>)
}

const YearSelector = (props) => {
    const [value, setValue] = React.useState((props.value));
    const [open, setOpen] = React.useState(false);
    return (<LocalizationProvider dateAdapter={AdapterLuxon} >
        <DatePicker
            views={['year']}
            label={props.title}
            value={value}
            openTo="year"
            open={open}
            onChange={(newValue) => {
                setValue(newValue);
                props.setDate(endOfYear(newValue.toJSDate()));
                props.setStartDate(startOfYear(newValue.toJSDate()));
            }}
            onMonthChange={() => { setOpen(false) }}
            onOpen={() => { setOpen(true) }}
            onClose={() => { setOpen(false) }}
            inputFormat="yyyy"
            renderInput={(params) => <TextField {...params} helperText={null} />}
        />
    </LocalizationProvider>)
}

const MonthSelector = (props) => {
    const [value, setValue] = React.useState((props.value));
    const [open, setOpen] = React.useState(false);
    return (<LocalizationProvider dateAdapter={AdapterLuxon} >
        <DatePicker
            views={['month', 'year']}
            label={props.title}
            value={value}
            openTo="month"
            open={open}
            onChange={(newValue) => {
                setValue(newValue);
                console.log(newValue.toJSDate());
                props.setDate(endOfMonth(newValue.toJSDate()));
                props.setStartDate(startOfMonth(newValue.toJSDate()));
            }}
            onMonthChange={() => { setOpen(false) }}
            onOpen={() => { setOpen(true) }}
            onClose={() => { setOpen(false) }}
            inputFormat="MMM yyyy"
            renderInput={(params) => <TextField {...params} helperText={null} />}
        />
    </LocalizationProvider>)
}

const WeekSelector = (props) => {
    const [value, setValue] = React.useState((props.value));
    const [open, setOpen] = React.useState(false);
    const [weekValue, setWeekValue] = React.useState(0);
    return (<Stack component="form" noValidate spacing={3} style={{display:'inline'}}>
        <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Date</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={weekValue}
                label="Date"
                onChange={(event) => {
                    var weekVal = Number(event.target.value);
                    props.setStartDate(shiftHours(startOfWeek(new Date()), weekVal*7*24));
                    props.setDate(shiftHours(endOfWeek(new Date()), weekVal*7*24));
                    setWeekValue(weekVal);
                }}
            >
                <MenuItem value={-4}>Four Weeks Ago</MenuItem>
                <MenuItem value={-3}>Three Weeks Ago</MenuItem>
                <MenuItem value={-2}>Two Weeks Ago</MenuItem>
                <MenuItem value={-1}>Last Week</MenuItem>
                <MenuItem value={0}>This Week</MenuItem>
                <MenuItem value={1} > Next Week</MenuItem>
                <MenuItem value={2} > In Two Weeks</MenuItem>
                <MenuItem value={3} > In Three Weeks</MenuItem>
                <MenuItem value={4} > In Four Weeks</MenuItem>
            </Select>
        </FormControl>

    </Stack >);
}